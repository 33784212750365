<template>
  <v-app class="momma-theme">
    <!-- Left Content -->
    <v-app-bar-nav-icon
      fixed
      class="d-block d-lg-none me-1"
      @click="isDrawerOpen = !isDrawerOpen"
    ></v-app-bar-nav-icon>
    <v-badge
      v-if="notifsUnread"
      color="success"
      class="left position-absolute mob-badge mt-11 ml-7"
      overlap
      bordered
      :content="notifsUnread + newsUnread"
      @click="isDrawerOpen = !isDrawerOpen"
    ></v-badge>
    <vertical-nav-menu
      :is-drawer-open.sync="isDrawerOpen"
      :notifs-unread="notifsUnread"
      :news-unread="newsUnread"
    ></vertical-nav-menu>
    <div
      v-if="$isMobile"
      class="mute-btn"
    >
      <v-icon
        v-if="muted"
        outlined
        @click="toggleMute"
      >
        {{ icons.mdiVolumeOff }}
      </v-icon>
      <v-icon
        v-else
        outlined
        @click="toggleMute"
      >
        {{ icons.mdiVolumeHigh }}
      </v-icon>
    </div>
    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="d-flex align-center main-top-menu">
        <v-spacer></v-spacer>
        <GuestMenu v-if="!user.isLoggedin"></GuestMenu>
        <div
          v-else
          id="game-menu"
          clas="mr-1"
        >
          <router-link
            v-if="dapperAddress"
            to="/my-team"
          >
            <v-list-item>
              <v-avatar
                :size="gAvatar"
                class="white--text font-weight-medium me-2"
              >
                <v-img :src="require('@/assets/images/misc/FKpoints.png')"></v-img>
              </v-avatar>
              <div class="d-flex align-center flex-grow-1 flex-wrap coins">
                <div class="me-1">
                  <div class="font-weight-semibold">
                    <span class="text--primary text-base me-1">{{ myPowerPoints }}</span>
                  </div>
                  <v-list-item-subtitle class="text-xs">
                    Points
                  </v-list-item-subtitle>
                </div>
              </div>
            </v-list-item>
          </router-link>
          <router-link
            v-else
            to="/my-team"
          >
            <v-list-item>
              <v-avatar
                :size="gAvatar"
                class="white--text font-weight-medium me-2"
              >
                <v-img :src="require('@/assets/images/misc/FKpoints.png')"></v-img>
              </v-avatar>
              <div class="d-flex align-center flex-grow-1 flex-wrap ">
                <div class="me-1">
                  <div class="font-weight-semibold">
                    <span class="text--primary text-base me-1">{{ myPoints }}</span>
                  </div>
                  <v-list-item-subtitle class="text-xs">
                    Points
                  </v-list-item-subtitle>
                </div>
              </div>
            </v-list-item>
          </router-link>
          <router-link
            to="/my-team"
            class="d-none"
          >
            <v-list-item>
              <v-avatar
                :size="gAvatar"
                class="white--text font-weight-medium me-2"
              >
                <v-img :src="fighterIcon"></v-img>
              </v-avatar>
              <div class="d-flex align-center flex-grow-1 flex-wrap cooins">
                <div class="me-1">
                  <div class="font-weight-semibold">
                    <span
                      v-if="coinsLoaded"
                      class="text--primary text-base me-1"
                    >{{
                        teamValue|toCurrencyNoDec
                      }}</span>
                    <span
                      v-else
                      class="text--primary text-base me-1"
                    >...</span>
                  </div>
                  <v-list-item-subtitle class="text-xs">
                    Team Value
                  </v-list-item-subtitle>
                </div>
              </div>
            </v-list-item>
          </router-link>
          <router-link to="/connect-wallet">
            <v-list-item>
              <v-avatar
                :size="gAvatar"
                class="white--text font-weight-medium me-2"
              >
                <v-img :src="coinIconGold"></v-img>
              </v-avatar>
              <div class="d-flex align-center flex-grow-1 flex-wrap coins">
                <div class="me-1">
                  <div class="font-weight-semibold">
                    <span
                      v-if="coinsLoaded"
                      class="text--primary text-base me-1"
                    >{{
                        myGoldCoins|toCurrency
                      }}</span>
                    <span
                      v-else
                      class="text--primary text-base me-1"
                    >...</span>
                  </div>
                  <v-list-item-subtitle class="text-xs hidden-sm-and-down">
                    Gold KombatCoins
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-xs hidden-md-and-up">
                    GKC
                  </v-list-item-subtitle>
                </div>
              </div>
            </v-list-item>
          </router-link>
          <router-link to="/connect-wallet">
            <v-list-item class="mr-0">
              <v-avatar
                :size="gAvatar"
                class="white--text font-weight-medium me-2"
              >
                <v-img :src="coinIconSilver"></v-img>
              </v-avatar>
              <div class="d-flex align-center flex-grow-1 flex-wrap coins">
                <div class="me-1">
                  <div class="font-weight-semibold">
                    <span
                      v-if="coinsLoaded"
                      class="text--primary text-base me-1"
                    >{{
                        mySilverCoins|toCurrencyNoDec
                      }}</span>
                    <span
                      v-else
                      class="text--primary text-base me-1"
                    >...</span>
                  </div>
                  <v-list-item-subtitle class="text-xs hidden-sm-and-down">
                    Silver KombatCoins
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-xs hidden-md-and-up">
                    SKC
                  </v-list-item-subtitle>
                </div>
              </div>
            </v-list-item>
          </router-link>
        </div>
        <app-bar-user-menu
          v-if="user.isLoggedin"
          class="ml-4"
        ></app-bar-user-menu>
      </div>
    </v-app-bar>

    <v-main :class="user.isLoggedin === 'true' ? 'backgroundDefault' : 'backgroundOctagon' ">
      <div
        v-if="!user.isLoggedin"
        class="mt-6"
      ></div>
      <div class="app-content-container boxed-container px-4 pb-4">
        <v-scroll-y-transition mode="out-in">
          <slot></slot>
        </v-scroll-y-transition>
      </div>
    </v-main>
    <audio
      id="daudio"
      ref="audio"
      :src="audio.src"
    ></audio>
    <audio
      id="audioPowerup"
      ref="audio1"
      src="@/assets/sounds/Puzzle_Game_PowerUp_Light_03.wav"
    ></audio>
    <audio
      id="acaudio"
      ref="acaudio"
      src="@/assets/sounds/Achievement.mp3"
    ></audio>
    <div v-for="(achievementData, idx) in achievementList" :key="achievementData.id">
      <Achievement v-if="!achievementData.read" :delay="idx" :achievement="achievementData"
      ></Achievement>
    </div>
    <div class="text-center">
      <v-snackbar
        v-model="snackNotify"
        top="top"
        color="success"
        outlined
        timeout="-1"
        class="text-center"
      >
        {{ pushNotification.body }}
        <br>
        <div class="text-center">
          <v-btn
            class="mt-2 mr-1"
            outlined
            color="success"
            variant="text"
            small
            :to="pushNotification.url"

          >
            View
          </v-btn>
          <v-btn
            class="mt-2 ml-1"
            outlined
            variant="text"
            small
            @click="snackNotify = false"
          >
            close
          </v-btn>
        </div>
      </v-snackbar>
    </div>
    <v-footer
      color="transparent"
      class="pa-0"
    >
      <div class="w-full mb-3 mt-3 mr-2">
        <div class="mx-2 d-flex justify-end">
          <span>
            <a
              href="https://support.mommagame.com/"
              target="_blank"
              class="text-decoration-none text-sm"
            >Support</a>
          </span>
          <span
            v-if="!user.isLoggedin"
            class="mr-3 ml-3"
          >
            <a
              href="https://support.mommagame.com/en/article/terms-of-service-qhnr7c/"
              class="text-decoration-none text-sm"
            >Terms of service</a>
          </span>
          <span
            v-if="!user.isLoggedin"
            clsss="mr-4"
          >
            <a
              href="https://support.mommagame.com/en/article/privacy-policy-hlxqnr/"
              class="text-decoration-none text-sm"
            >Privacy Policy</a>
          </span>
          <span>
          <a
            href="https://discord.gg/X2qvCm4ufu"
            target="_blank"
            class="text-decoration-none text-xs ml-2"
          ><v-icon>{{ icons.mdiDiscord }}</v-icon></a>
          </span>
          <span>
            <a
              href="https://twitter.com/mastersofmma"
              target="_blank"
              class="text-decoration-none text-xs ml-2"
            ><v-icon>{{ icons.mdiTwitter }}</v-icon></a>
          </span>
          <span>
            <a
              href="https://www.instagram.com/masters_of_mma/"
              target="_blank"
              class="text-decoration-none text-xs ml-2 mr-1"
            ><v-icon>{{ icons.mdiInstagram }}</v-icon></a>
          </span>
          <span class="d-sm-inline d-none copyright mr-2 ml-2 mt-1 text-sm">
            &copy; {{ new Date().getFullYear() }} <a
            href="https://mommagame.com"
            class="text-decoration-none"
            target="_blank"
          >Masters of MMA</a>
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import {ref} from '@vue/composition-api'
import {
  mdiBellOutline,
  mdiBattery,
  mdiCalendar,
  mdiTwitter,
  mdiDiscord,
  mdiInstagram,
  mdiVolumeOff,
  mdiVolumeHigh,
} from '@mdi/js'
import db from '@/db/init'
import dbFunctions from '@/db'
import GuestMenu from '@/layouts/components/GuestMenu.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import Achievement from '@/views/components/Achievements.vue'
import 'firebase/firestore'
import firebase from 'firebase/app'

export default {
  components: {
    GuestMenu,
    VerticalNavMenu,
    AppBarUserMenu,
    Achievement,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,
      icons: {
        mdiBellOutline,
        mdiBattery,
        mdiCalendar,
        mdiTwitter,
        mdiInstagram,
        mdiVolumeOff,
        mdiVolumeHigh,
        mdiDiscord,
      },
      bellAlert: false,
    }
  },
  data() {
    return {
      user: {},
      gAvatar: 30,
      mySilverCoins: 0,
      myGoldCoins: 0,
      myPoints: this.$store.state.user?.profile?.totalPoints,
      dapperAddress: '',
      myPowerPoints: this.$store.state.user?.profile?.totalCombined,
      teamValue: 0,
      coinsLoaded: false,
      fpIcon: require('@/assets/images/misc/FKpoints.png'),
      coinIconGold: require('@/assets/images/buy/KombatCoinGold.png'),
      coinIconSilver: require('@/assets/images/buy/KombatCoinSilver.png'),
      fighterIcon: require('@/assets/images/avatars/FantasyFighterCard.png'),
      refreshLoad: false,
      siteData: {},
      muted: false,
      tour: '',
      notifsUnread: 0,
      newsUnread: 0,
      achievementList: [],
      snackNotify: false,
      pushNotification: {
        title: 'title',
        body: 'Pick\'ems open',
        image: '',
        url: '/dashboard'
      },
      nextEventID: '',
      admin: false,
      audio: {
        src: '',
      },
    }
  },
  computed: {
    computedAchievementList() {
      return this.achievementList.filter(a => a.pages.includes(window.location.href.substring(window.location.href.lastIndexOf('/') + 1)))
    }
  },
  watch: {
    $route() {
      this.achievementList = []
      this.checkOnPageChange()
    }
  },
  mounted() {
    this.deviceCheck()
    firebase.auth()
      .onAuthStateChanged(user => {
        if (user) {
          this.user.isLoggedin = true
          this.checkUser()
        } else {
          this.user.isLoggedin = false
        }
      })

    window.addEventListener('onorientationchange', this.deviceCheck())
    window.addEventListener('resize', this.deviceCheck())
    this.audio = document.getElementById('daudio')
    this.$messaging.onMessage(payload => {
      this.pushNotification = payload.notification
      console.log('Message received', payload)
      if (this.pushNotification.title == 'Event Reminder') {
        db.collection('admin')
          .doc('A000000')
          .get()
          .then(doc => {
            this.nextEventID = doc.data().currentEventId
            this.pushNotification.body = doc.data().currentEventName + ' pick\'em is now open.'
            if (this.nextEventID) {
              this.pushNotification.url = '/tournaments/ufc-fight-night/' + this.nextEventID + '/'
            }
            this.snackNotify = true
          })
      }
    })
  },
  methods: {
    deviceCheck() {
      const viewportwidth = this.$vuetify.breakpoint.width

      // const portrait = window.matchMedia('(orientation: portrait)').matches
      if (viewportwidth <= 420) {
        this.gAvatar = 25
      } else if (viewportwidth <= 480) {
        this.gAvatar = 30
      } else if (viewportwidth <= 500) {
        this.gAvatar = 30
      } else if (viewportwidth <= 550) {
        this.gAvatar = 30

        // decrease for mobile landscape
      } else if (viewportwidth > 600 && this.$vuetify.breakpoint.mobile) {
        this.gAvatar = 25
      } else {
        this.gAvatar = 30
      }
    },
    checkOnPageChange() {
      if (this.$store.state.user?.isLoggedin) {
        this.dapperAddress = this.$store.state.user?.profile?.dapperAddress
        dbFunctions.readUsersProfile(this.user.uid)
          .then(profileData => {
            this.user.profile = profileData
            this.$store.dispatch('getUserProfile', this.user)
            this.mySilverCoins = profileData.silverKombatCoins || 0
            this.myGoldCoins = profileData.goldKombatCoins || 0
            this.myPoints = profileData.totalPoints || 0

            this.getFighters()
            if (!this.$store.state.user?.uid) {
              this.startTour()
            }
          })
        db.collection('achievementsLog')
          .where('userId', '==', this.user.uid)
          .where('read', '==', false)
          .limit(1)
          .onSnapshot(querySnapshot => {
            this.achievementList = []
            querySnapshot.docs.forEach(data => {
              let adata = data.data()
              adata.id = data.id
              if (adata.pages.includes(window.location.href.substring(window.location.href.lastIndexOf('/') + 1))) {
                this.achievementList.push(adata)
              }
            })
          })

      } else {
        console.log('not logged in')
      }
    },
    async checkUser() {
      if (this.$store.state?.user) {
        this.user = this.$store.state.user
      } else {
        this.user = {}
        this.user.isLoggedin = false
      }
      if (this.$store.state.user?.isLoggedin || this.user.isLoggedin) {
        this.user = this.$store.state.user
        this.refreshLoad = true
        this.coinsLoaded = false
        this.teamValue = 0
        this.admin = this.$store.state.user.profile?.admin
        db.collection('profiles')
          .doc(this.user.uid)
          .onSnapshot(doc => {
            this.user.profile = doc.data()
            if (this.user.profile == undefined && this.user.isLoggedin) {
              this.$router.push('/logout')
            }
            this.mySilverCoins = this.user.profile?.silverKombatCoins || 0
            this.myGoldCoins = this.user.profile?.goldKombatCoins || 0
            this.$store.dispatch('getUserProfile')
            this.refreshLoad = false
            this.coinsLoaded = true
          })
        if (window.$crisp) {
          window.$crisp?.push(['safe', true], ['set', 'user:email', [this.user.email]])
          window.$crisp?.push(['safe', true], ['set', 'user:nickname', [this.user.displayName]])
        }
        if (this.user.profile?.muted) {
          this.muted = true
        }
        if (this.user?.uid) {
          db.collection('notifications')
            .where('to', '==', this.user.uid)
            .where('read', '==', false)
            .onSnapshot(querySnapshot => {
              this.notifsUnread = 0
              querySnapshot.docs.forEach(() => {
                this.notifsUnread += 1
              })
              if (this.notifsUnread > 0) {
                this.audio.src = require('@/assets/sounds/New Message 3.mp3')
                this.audio.volume = 0.1
                this.audio.play()
              }
            })
          db.collection('prizeClaims')
            .where('userUid', '==', this.user.uid)
            .where('claimed', '==', false)
            .onSnapshot(querySnapshot => {
              if (querySnapshot.size > 0) {
                this.notifsUnread += 1
              }
              if (this.notifsUnread > 0) {
                this.audio.src = require('@/assets/sounds/New Message 3.mp3')
                this.audio.volume = 0.1
                this.audio.play()
              }
            })
          db.collection('wheelSpinsAllowed')
            .where('userUid', '==', this.user.uid)
            .where('claimed', '==', false)
            .onSnapshot(querySnapshot => {
              if (querySnapshot.size > 0) {
                this.notifsUnread += 1
              }
              if (this.notifsUnread > 0) {
                this.audio.src = require('@/assets/sounds/New Message 3.mp3')
                this.audio.volume = 0.1
                this.audio.play()
              }
            })
        }
      } else {
        this.refreshLoad = false
        this.coinsLoaded = true
      }
      db.collection('news')
        .onSnapshot(querySnapshot => {
          this.newsUnread = 0
          querySnapshot.docs.forEach(data => {
            if (this.user.profile?.lastReadNews) {
              if (data.data().created > this.user.profile.lastReadNews) {
                this.newsUnread += 1
              }
            } else {
              this.newsUnread = 1
            }
          })
        })
    },
    async getFighters() {
      this.teamValue = 0
      await dbFunctions.getAllTeamFighters(this.user.uid)
        .then(querySnapshot => {
          this.teamValue = 0
          querySnapshot.forEach(doc => {
            this.teamValue += doc.data().cost
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    tourComplete() {
      const fields = {onBoardBetaTour: true}
      dbFunctions.updateUsersProfile(this.user, fields)
    },

    openChat() {
      this.tour.complete()
      if (window.$crisp) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
      }
    },
    toggleMute() {
      this.muted = !this.muted
      const AudioElements = document.getElementsByTagName('audio')
      AudioElements.forEach(element => {
        const audioElement = element
        if (this.muted) {
          audioElement.muted = true
          if (audioElement.id === 'game-music') {
            audioElement.pause()
          }
        } else {
          audioElement.muted = false
          if (audioElement.id === 'game-music') {
            audioElement.volume = 0.1
            console.log(audioElement)
            console.log(audioElement.volume)
            audioElement.play()
          }
        }
      })
    },
    startTour() {
      if (!this.user.profile?.onBoardBetaTour) {
        // tour
        this.$nextTick(() => {
          this.tour = this.$shepherd({
            useModalOverlay: false,
            scrollTo: true,
            classPrefix: 'momma-tour-',
          })

          this.tour.on('complete', () => {
            this.tourComplete()
          })
          this.tour.addStep({
            attachTo: {
              element: this.$el,
              on: 'bottom-start',
            },
            buttons: [
              {
                text: 'Next',
                action: this.tour.next,
              },
            ],
            text: `<img class="mike-top" src="${require('@/assets/images/avatars/mike-top-white.png')}"/><p class="mb-6">Hi ${this.user.displayName || 'Fight Fan'}!</p><br><p class="mt-4">Welcome to Masters of MMA, thanks for joining our Beta program. I'm Coach Chris, over the coming weeks we will be introducing "Career Mode". I'm here to help you get setup and running.</brp>`,
          })
          this.tour.addStep({
            attachTo: {
              element: this.$el,
              on: 'bottom-start',
            },
            buttons: [
              {
                text: 'Start Chat',
                action: this.openChat,
              },
              {
                text: 'Close',
                action: this.tour.complete,
              },
            ],
            text: `<img class="mike-top" src="${require('@/assets/images/avatars/mike-top-white.png')}"/>In the meantime if you have any questions feel free to reach out to one of our helpful humans.`,
          })
          this.tour.start()
        })
      }
    },
  },
}
</script>
<style>

.main-top-menu {
  position: absolute;
  right: 25px;
}

.mobile .main-top-menu {
  position: absolute;
  right: 5px;
}

.audio__notice {
  display: none !important;
  opacity: 0 !important;
}

.mute-btn {
  position: fixed;
  left: 13px;
  top: 61px;
  cursor: pointer;
  z-index: 5;
  border: 1px solid #ddd;
  border-radius: 50%;
  opacity: 0.6;
  background: #05092db3;
}

span.v-badge__badge.success {
  color: #fff;
  text-shadow: 0px 0px 1px black;
  font-family: 'Audiowide';
  font-size: 11px;
}

.v-badge.mob-badge {
  position: fixed;
  z-index: 6;
  margin-left: 29px !important;
}

img.mike-top {
  position: absolute;
  left: 177px;
  top: -150px;
  height: 150px;
}

.mobile img.mike-top {
  position: absolute;
  left: 160px;
  top: -150px;
  height: 150px;
}

.shepherd-arrow {
  left: -20px !important;
}

.shepherd-element.shepherd-enabled {
  margin-left: 20px !important;
  top: -30px !important;
  width: 350px !important;
  max-width: 90% !important;
  font-family: 'Roboto', Arial, sans-serf, serif;
}

.mobile .shepherd-element.shepherd-enabled {
  max-width: 90% !important;
  top: -40px !important;
}
</style>

<style scoped>
@import "~shepherd.js/dist/css/shepherd.css";

.mobile .site-status {
  height: 20px;
  background: #010224;
  z-index: 9;
  padding: 5px 10px;
  margin-top: 3px;
  margin-bottom: 15px;
  font-size: 12px;
}

button.v-app-bar__nav-icon.v-btn.v-btn--fixed {
  position: fixed;
  z-index: 6;
  margin: 8px;
  border: 2px solid;
  opacity: 0.7;
}

div#game-menu {
  display: flex;
}

.mobile div#game-menu span.text--primary.text-base.me-1 {
  font-size: 10px !important;
  line-height: 8px !important;
}

div#game-menu span.text--primary.text-base.me-1 {
  font-size: 12px !important;
  line-height: 9px !important;
}

div#game-menu .d-flex.align-center.flex-grow-1.flex-wrap.coins {
  margin-top: -3px !important;
  line-height: 22px;
}

.mobile div#game-menu .v-list-item__subtitle.text-xs {
  font-size: 9px !important;
  line-height: 8px !important;
  margin: -3px 0 7px 0
}

div#game-menu .v-list-item__subtitle.text-xs {
  font-size: 12px !important;
  line-height: 13px !important;
  margin: -3px 0 0 0;
}

.mobile div#game-menu .v-list-item .me-2 {
  margin-right: 5px !important;
}

div#game-menu .v-list-item {
  padding: 0 3px;
}

.v-app-bar ::v-deep {

  .v-toolbar__content {
    padding: 0;
  }

}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.memberpricing-bg {
  position: relative;
}

.membership-pricing {
  text-align: center;

  sup {
    font-size: 3.75rem;
    top: 9px;
  }

}

</style>
